<template>
  <div class="aiRecordTab">
    <van-tabs
      v-model="activeValue"
      title-active-color="#B09FD9"
      title-inactive-color="#333333"
      :animated="true"
    >
      <van-tab name="queue" title="排队 /">
        <AiUndressList ref="undressChildRef1" status="1"></AiUndressList>
      </van-tab>
      <van-tab name="success" title="成功 /">
        <AiUndressList ref="undressChildRef2" status="2"></AiUndressList>
      </van-tab>
      <van-tab name="fail" title="失败">
        <AiUndressList ref="undressChildRef3" status="3"></AiUndressList>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import AiUndressList from "./aiUndressList.vue";

export default {
  name: "aiUndressTab",
  components: {
    AiUndressList,
  },
  computed: {},
  data() {
    return {
      activeValue: 0,
      showBigImg: false,
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      let name = this.$route.query["name"];
      if (name) {
        this.activeValue = name;
      } else {
        this.activeValue = "queue";
      }
    });
  },
  methods: {
    delMethod(id) {
      if (this.activeValue == "queue") {
        this.$refs.undressChildRef1.delId(id);
      } else if (this.activeValue == "success") {
        this.$refs.undressChildRef2.delId(id);
      } else if (this.activeValue == "fail") {
        this.$refs.undressChildRef3.delId(id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .van-tabs__wrap {
  height: 18px !important;
  justify-content: flex-start !important;
  margin: 12px 0 !important;
  width: 120px;
  background: rgba(0, 0, 0, 0) !important;
}
/deep/ .van-tabs__nav {
  background: rgba(0, 0, 0, 0) !important;
  justify-content: flex-start !important;
}
/deep/ .van-tab {
  display: contents;
}

/deep/ .van-tab__text {
  margin-right: 5px !important;
  font-size: 12px;
}

/deep/ .van-tabs__nav {
  background: #f1f1f1;
}

/deep/ .van-tabs__nav--line {
  padding-left: 14px !important;
}

/deep/ .van-tabs__line {
  display: none !important;
}
</style>

<template>
  <div class="aiRecordList">
    <PullRefresh v-if="!isLoading" :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad">
      <div class="recordBox flex-center-center" v-for="item in list" :key="item.id">
        <div class="leftBox flex-vcenter">
         <div @click="openBigImg(item.picture)" v-if="item.status == 0">
          <ImgDecypt
            class="cover"
            :src="item.picture[0]"
          ></ImgDecypt>
         </div>
         <div @click="openPlayVideo(item.url)" v-else>
          <ImgDecypt class="cover" :src="item.picture[0]"></ImgDecypt>
         </div>
        </div>
        <div class="recordInfo">
          <div class="id">
            ID
            <span>{{ item.id }}</span>
          </div>
          <div class="time">
            {{ item.createdAt }}
            <span v-if="item.status == -1" @click="openReasonDialog(item.remark)">查看原因</span>
          </div>
        </div>
        <div class="rightIcon" @click="openDelChangeVideoFaceDialog(item.id)">
          <SvgIcon
            iconClass="delIconNew"
            class="delBtn"
          ></SvgIcon>
        </div>
      </div>
    </PullRefresh>
    <Loading v-else />
  </div>
</template>
<script>
import PullRefresh from "@/components/PullRefresh";
import Loading from "@/components/Loading";
import { generateImg, getAiMod, getAiChangeFaceVideoRecord } from "@/api/mine";
import ImgDecypt from "@/components/ImgDecypt";
import {dateFormat1} from "@/utils/index.js";
import SvgIcon from "@/components/SvgIcon";


export default {
  name: "aiChangeVideoFaceList",
  inject: ["openBigImg", "openPlayVideo", "openDelChangeVideoFaceDialog", "openReasonDialog"],
  props: {
    status: String,
  },
  components: {
    ImgDecypt,
    PullRefresh,
    Loading,
    SvgIcon,
  },
  computed: {},
  data() {
    return {
     state: {
      delId: "", // 当前删除ID
     },
      pageNumber: 1,
      pageSize: 10,
      list: [],
      loading: true, // loading
      isLoading:true,
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
      localStatus: "",
    };
  },
  watch: {
   status: {
    immediate: true,
    handler(newStatus) {
     this.localStatus = newStatus;
    },
   },
  },
  created() {},
  mounted() {
    this.getList("refresh");
  },
  methods: {
    /**
     * 获取列表
     */
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        status: this.status,
      };
      try {
        let res = await this.$Api(getAiChangeFaceVideoRecord, req);
        const { data } = res;
        console.log(res);
        this.loading = false;
        this.refreshing = false;
        this.isLoading = false;
        let list = data.list || [];
        for (const item of list) {
         item.createdAt = dateFormat1(item.createdAt);
        }
        if (type === "refresh") {
          this.list = list;
        } else {
          this.list = this.list.concat(list);
        }
        if (this.pageNumber == 1 && !this.list.length) {
          this.isNoData = true;
        }
        if (list.length < this.pageSize) {
          this.finished = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    onRefresh() {
        this.pageNumber = 1;
        this.refreshing = true;
        this.finished = false;
        this.isNoData = false;
        this.getList('refresh');
    },
    onLoad(){
        this.loading = true;
        if (this.error) {
            this.error = false;
        } else {
            this.pageNumber++
        }
        if(this.isTypeLoading == 0){
            this.getList();
        }
    },
   delId(id) {
    let newArr = [];
    newArr = this.list.filter((item) => {
     if (item.id != id) return item;
    });
    this.list = newArr;
    if (this.list.length == 0) {
     this.finished = false;
     this.isNoData = true;
    }
   },
  },
};
</script>
<style lang="scss" scoped>
.aiRecordList {
 overflow: auto;
 height: calc(100vh - 155px);
 padding: 0 16px;

 .recordBox {
  //height: 92px;
  //width: 100%;
  margin: 0 auto 10px;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 12px;
  justify-content: flex-start;

  .leftBox {
   .cover {
    height: 68px;
    width: 68px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 12px;
   }
  }
  .recordInfo {
   .id {
    font-size: 16px;

    span {
     font-size: 12px;
     color: rgb(168, 168, 168);
    }
   }

   .time {
    font-size: 10px;
    color: rgb(168, 168, 168);
    margin-top: 9px;

    span {
     font-size: 12px;
     color: white;
     margin-left: 5px;
    }
   }
  }
  .rightIcon{
   margin-left: 35px;
   .delBtn {
    height: 20px;
    width: 20px;
   }
  }
 }
}
</style>


<template>
  <div class="aiRecordList overflow-y-auto">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <div
        class="recordBox flex-center-center"
        v-for="item in list"
        :key="item.id"
      >
        <div
          class="leftBox flex-vcenter"
          @click="
            openBigImg(localStatus == '2' ? [item.newPic[0]] : [item.originPic])
          "
        >
          <ImgDecypt
            class="cover"
            :src="localStatus == '2' ? item.newPic[0] : item.originPic"
          ></ImgDecypt>
        </div>
        <div class="recordInfo">
          <div class="id">
            ID
            <span>{{ item.id }}</span>
          </div>
          <div class="time">
            {{ item.createdAt }}
            <span
              v-if="item.localStatus == 3"
              @click="openReasonDialog(item.remark)"
              >查看原因</span
            >
          </div>
        </div>
        <div class="rightIcon" @click="openDelChangeImgFaceDialog(item.id)">
          <SvgIcon iconClass="delIconNew" class="delBtn"></SvgIcon>
        </div>
      </div>
    </PullRefresh>
  </div>
</template>
<script>
import PullRefresh from "@/components/PullRefresh";
// import Loading from "@/components/Loading";
import { generateImg, getAiMod, getAiChangeFaceImgRecord } from "@/api/mine";
import ImgDecypt from "@/components/ImgDecypt";
import SvgIcon from "@/components/SvgIcon";
import { dateFormat1 } from "@/utils/index.js";

export default {
  name: "aiChangeImgFaceList",
  inject: ["openBigImg", "openDelChangeImgFaceDialog", "openReasonDialog"],
  props: {
    status: String,
  },
  components: {
    PullRefresh,
    ImgDecypt,
    SvgIcon,
  },
  computed: {},
  data() {
    return {
      state: {
        delId: "", // 当前删除ID
      },
      pageNumber: 1,
      pageSize: 10,
      list: [],
      localStatus: "",
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
    };
  },
  watch: {
    status: {
      immediate: true,
      handler(newStatus) {
        this.localStatus = newStatus;
      },
    },
  },
  created() {},
  mounted() {
    this.getList("");
  },
  methods: {
    /**
     * 获取列表
     */
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        status: this.localStatus,
      };
      try {
        let res = await this.$Api(getAiChangeFaceImgRecord, req);
        this.loading = false;
        this.refreshing = false;
        let list = res.data.list || [];
        for (const item of list) {
          item.localStatus = this.localStatus;
          item.createdAt = dateFormat1(item.createdAt);
        }
        if (type === "refresh") {
          this.list = list;
        } else {
          this.list = this.list.concat(list);
        }
        if (this.pageNumber == 1 && !this.list.length) {
          this.isNoData = true;
        }
        if (list.length < this.pageSize) {
          this.finished = true;
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
        this.error = true;
      }
    },
    onRefresh() {
      this.state.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList("refresh");
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.state.pageNumber++;
      }
      this.getList();
    },
    delId(id) {
      let newArr = [];
      newArr = this.list.filter((item) => {
        if (item.id != id) return item;
      });
      this.list = newArr;
      if (this.list.length == 0) {
        this.finished = false;
        this.isNoData = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.aiRecordList {
  height: calc(100vh - 155px);
  padding: 0 16px;

  .recordBox {
    //height: 92px;
    //width: 100%;
    margin: 0 auto 10px;
    background: #ffffff;
    border-radius: 8px;
    padding: 12px;
    justify-content: flex-start;

    .leftBox {
      .cover {
        height: 68px;
        width: 68px;
        border-radius: 4px;
        overflow: hidden;
        margin-right: 12px;
      }
    }
    .recordInfo {
      .id {
        font-size: 16px;

        span {
          font-size: 12px;
          color: rgb(168, 168, 168);
        }
      }

      .time {
        font-size: 10px;
        color: rgb(168, 168, 168);
        margin-top: 9px;

        span {
          font-size: 12px;
          color: white;
          margin-left: 5px;
        }
      }
    }
    .rightIcon {
      margin-left: 35px;
      .delBtn {
        height: 20px;
        width: 20px;
      }
    }
  }
}
</style>
